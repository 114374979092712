<template>
  <v-container @keydown.enter="login">
    <v-row align="center" justify="center">
      <v-col lg="6" sm="8" md="4">
        <v-card class="login-card" elevation="2">
          <v-img src="@/assets/coloring_logo.png" class="logo" />
          <v-card-text>
            <v-form>
              <v-text-field
                v-model="data.username"
                :label="$t('username')"
                outlined
                dense
                rounded
                prepend-icon="mdi-account"
                autofocus
              />
              <v-text-field
                v-model="data.password"
                :label="$t('password')"
                type="password"
                outlined
                dense
                rounded
                prepend-icon="mdi-lock"
              />
              <v-select
                v-model="lang"
                :items="langItems"
                :label="$t('language')"
                item-text="text"
                item-value="value"
                outlined
                dense
                rounded
                prepend-icon="mdi-earth"
                @change="setLang"
              />
              <v-checkbox
                class="mt-n4"
                :label="$t('remember me')"
                v-model="remember"
              ></v-checkbox>

              <v-row class="justify-center mt-4">
                <v-btn
                  style="width: 30%;border-radius: 20px;"
                  :disabled="validate"
                  :loading="loading"
                  dense
                  rounded
                  @click="login"
                  color="primary"
                  class="ma-auto"
                  depressed
                  >{{ $t("agree") }}</v-btn
                >
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import auth, { Auth } from "../../../store/common/auth";
export default {
  data() {
    return {
      Auth,
      remember: false,
      data: {
        username: "",
        password: "",
      },
      lang: "ar",
      langItems: [
        { value: "ar", text: "العربية" },
        { value: "en", text: "english" },
      ],
    };
  },
  methods: {
    async login() {
      if (this.validate) return;
      await Auth.login(this.data);
      if (Auth.userType == "teacher") {
        this.$vuetify.theme.themes.light.primary = "#00695C";
      }
      if (this.remember) {
        localStorage.setItem("sama_user", JSON.stringify(this.data));
      } else localStorage.removeItem("sama_user");
    },
    setLang(e) {
      localStorage.setItem("lang", e);
      this.$i18n.locale = e;
      if (e == "ar") {
        this.$vuetify.rtl = true;
      } else {
        this.$vuetify.rtl = false;
      }
    },
  },
  computed: {
    validate() {
      return (
        this.data.username == "" ||
        this.data.password == "" ||
        this.data.password.length < 8
      );
    },
    loading() {
      return Auth.loginState.loading;
    },
  },
  created() {
    Auth.token = null;
    this.$vuetify.theme.themes.light.primary = "#007bff";
    Auth.userType == "admin";
    let user = localStorage.getItem("sama_user");
    if (user) {
      this.data = JSON.parse(user);
      this.remember = true;
    }
  },
};
</script>
<style scoped>
.login-card {
  background-color: #f5f5f5;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 32px;
  border-radius: 8px;
}

.logo {
  width: 100px;
  margin: auto;
  margin-bottom: 50px;
}

.v-input__control {
  border-radius: 8px;
}

.v-btn {
  border-radius: 8px;
}

.router-link {
  color: #1976d2;
}

.router-link:hover {
  text-decoration: none;
}

.font-weight-medium {
  font-weight: 500;
}
</style>
